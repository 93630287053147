import { render, staticRenderFns } from "./ContractList.vue?vue&type=template&id=36647a93&scoped=true&"
import script from "./ContractList.vue?vue&type=script&lang=js&"
export * from "./ContractList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36647a93",
  null
  
)

export default component.exports